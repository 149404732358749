@media screen and (max-width: 400px) {
  #features {
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #app,
  #gallery,
  #contact {
    width: 100%;
  }

  #header {
    width: 100%;
  }
}
